export const cards=[
{cls:"card_car",title:"Скидка<br />на Атом EV",price:"Скоро",coming:true,disabled:false,bottomtitle:"Электромобиль-гаджет с широкими возможностями персонализации и цифрового взаимодействия с городом",bg:"car.png",top:1,count:"ограниченное количество",real:false,bigtext:"Скоро станет доступен обмен протонов на эксклюзивную скидку на <a href='https://atom.auto' target='_blank'>Атом</a> — электромобиль-гаджет с широкими возможностями персонализации и цифрового взаимодействия с городом.",tip:"Особые"},
{cls:"card_brown",title:"Низкокалорийное печенье",price:"2 000",disabled:false,bottomtitle:"Bombbar Шоколадный брауни — наслаждение и польза. Лакомство без сахара, с высоким содержанием белка",bg:"cookie.png",top:1,count:"63",real:true,bigtext:"Шоколадное печенье Bombbar — наслаждение и польза! Лакомство без сахара, но с насыщенным вкусом шоколада и высоким содержанием белка.",tip:"Особые"},
{cls:"card_brown",title:"Протеиновые батончики",price:"4 500",disabled:false,bottomtitle:"Bombbar ассорти №4 — без сахара и лишних калорий, с высоким содержанием белка и клетчатки. 20 штук с 5 вкусами",bg:"baton.png",top:1,count:"29",real:true,bigtext:"В этих батончиках нет сахара и и лишних калорий, зато много белка и клетчатки. Попробуй ассорти — в набор входят следующие вкусы: печенье с кремом (х4), венские вафли (х4), солёная карамель (х4), малиновый чизкейк (х4), лимонный торт (х4).",tip:"Особые"},
{cls:"card_purple",title:"Яндекс. Станция Мини",price:"12 000",disabled:false,bottomtitle:"Крохотная, но функциональная —умная колонка с голосовым управлением, которая сделает твою жизнь проще",bg:"yandex_mini.png",top:2,count:"7",real:true,bigtext:"Миниатюрная, но функциональная — умная колонка с голосовым управлением, которая сделает твою жизнь проще.",tip:"Гаджеты"},
{cls:"card_orange",title:"Xiaomi Smart Band 8 Active",price:"8 000",disabled:false,bottomtitle:"Твой личный фитнес-тренер на запястье! Мониторит пульс, шаги и напоминает, когда пора двигаться",bg:"xioami_band.png",top:2,count:"5",real:true,bigtext:"Твой личный фитнес-тренер на запястье! Мониторит пульс, шаги и напоминает, когда пора двигаться. ",tip:"Гаджеты"},
{cls:"card_blue",title:"Футболка <br />тай-дай",price:"3 500",disabled:false,bottomtitle:"Уникальная тай-дай футболка из хлопка, которая будет только у тебя! Каждый принт неповторим!",bg:"t_shirt.png",top:1,count:"1",real:true,bigtext:"Уникальная тайдай-футболка из хлопка, которая будет только у тебя. Каждый принт неповторим! ",tip:"Мерч"},
{cls:"card_pink",title:"Яндекс <br />Плюс",price:"2 000",disabled:false,bottomtitle:"3 месяца<br />Музыка, фильмы,<br/>кешбэк баллами",bg:"yandex.png",top:1,count:"63",real:false,bigtext:"Подписка на 3 месяца - музыка, фильмы, а также кэшбек баллами Яндекса и другие плюшки.",tip:"Подписки"},
{cls:"card_aqua",title:"Телеграм<br/>Премиум",price:"2 750",disabled:false,bottomtitle:"Подписка на 3 месяца - отсутствие рекламы, расшифровка войсов, больше лимиты, и многое другое",bg:"telegram.png",top:1,count:"63",real:false,bigtext:"Подписка на 3 месяца - отсутствие рекламы, расшифровка войсов, больше лимиты, и многое другое.",tip:"Подписки"},
{cls:"card_darkgreen",title:"Эко-стеллаж<br />для зелени",price:"15 000",disabled:false,bottomtitle:"Собственная сити-ферма для выращивания зелени и овощей. Просто добавь воды! ",bg:"stel.png",top:2,count:"1",real:true,bigtext:"Футуристичный и функциональный стеллаж фитолампами для рассады и микрозелени с фитолампами “sunlike” спектра. Удобная конструкция позволяет собирать и разбирать стеллаж всего за 10-15 минут. <br /><br />Стеллаж изготовлен из перерабатываемого ABS пластика, каждая полка выдерживает до 70 кг, а блоки питания надежно защищены от влаги. Три съемных фитосветильника мощностью по 20W обеспечивают яркое и равномерное освещение. Создайте свою экоферму прямо у себя дома и наслаждайтесь цветущим садом круглый год! ",tip:"Особые"},
{cls:"card_dark",title:"Эйрподсы",price:"42 000",disabled:false,bottomtitle:"Минимум шума,<br />максимум стиля ",bg:"airpods.png",top:4,count:"2",real:true,bigtext:"Знаменитые беспроводные наушники, ставшие классикой. Легкие, стильные и функциональные. Они идеально подходят для активного образа жизни и обеспечивают высокое качество звука. Получить оригинальные AirPods бесплатно можно обменяв свои накопленные в приложении протоны. ",tip:"Гаджеты"},
{cls:"card_blue",title:"Офигенное<br />худи",price:"9 000",bottomtitle:"Лимитный мерч от наших дизайнеров",bg:"hudi.png",top:1,big:false,count:"26",real:true,tip:"Мерч"
,	opt:[
{name:'color',title:'Цвет',tip:"color",arr:[
{value:"#000",status:true},/*
{value:"#fff",status:true},
{value:"#c4c4c4",status:true},
{value:"#ff1414",status:true},
{value:"#117eff",status:true},
{value:"#60fd00",status:true},*/
]
},
{name:'size',title:'Размер',tip:"text",arr:[
{value:"XS",status:false},
{value:"S",status:false},
{value:"M",status:true},
{value:"L",status:true},
{value:"XL",status:true},
{value:"XXL",status:false},
]
},
		],slider:[
{"file": "/i/store/hudi/1.jpg","preview": "/i/store/hudi/1p.jpg"},
	],
bigtext:"Наше лимитированное худи с вышивкой – идеальное сочетание стиля и комфорта. Дизайн этого дропа разработан нашими дизайнерами ограниченной партией.<br /><br />Оверсайз крой и мягкий капюшон обеспечивают максимальный комфорт, позволяют свободно двигаться и чувствовать себя уютно в любых условиях. В кенгуру-кармане спереди можно хранить мелочи или согреть руки в непогоду. <br /><br />Худи изготовлено из 80% хлопка и 20% полиэстера, что гарантирует долговечность, мягкость и легкость в уходе, а вышитый рисунок никогда не сотрется."
},

{cls:"card_blue2",title:"Многоразовая<br />бутылка",price:"2 000",disabled:false,bottomtitle:"Сокращай использование пластика стильно",bg:"butilka.png",top:1,big:false,count:"50",real:true,bigtext:"Эта стильная и экологичная бутылка изготовлена из высококачественной нержавеющей стали, которая не только долговечна, но и безопасна для окружающей среды – используя многоразовую емкость, вы снижаете количество пластиковых отходов. Нержавеющая сталь не впитывает запахи и не влияет на вкус напитков, а также безопасна для многократного использования. ",tip:"Мерч"},
{cls:"card_more_orange",title:"Купон Flowwow<br />на 500р.",price:"500",disabled:false,bottomtitle:"Обменяй протоны на купон на 500 рублей и выбери что-то особенное для тех, кого любишь",bg:"cupon_flowwow.png",top:1,count:"10",real:false,bigtext:"Обменяй протоны на купон на 500 рублей и выбери что-то особенное: цветы, игрушки, сладости и многое другое для тех, кого любишь!<br />Купон действует на любую покупку на маркетплейсе Flowwow от 3500 рублей.",tip:"Особые"},
{cls:"card_more_orange",title:"Промокод -10%<br />Flowwow",price:"300",disabled:false,bottomtitle:"Не забывай время от времени баловать себя и своих близких. А Flowwow поможет тебе в этом",bg:"promo_flowwow.png",top:1,count:"1000",real:false,bigtext:"Не забывай время от времени баловать себя и своих близких! А Flowwow поможет тебе в этом :)<br />Промокод на скидку 10% действует при покупке кондитерских изделий на маркетплейсе Flowwow.",tip:"Особые"},
]
export const cardsCategory=['Всё','Подписки','Мерч','Гаджеты','Особые'];
/*
{title:"Я могу подарить кому-то товары из магазина за свои Протоны?",
text:"Да, Вы можете приобрести приз из нашего магазина в качестве подарка для другого человека. В таком случае, сообщите об этом в графе «Комментарий»."},
*/
export const faq=[
{title:"Что такое Протоны?",
text:"Протоны - это внутриигровая валюта в приложении Protone.app, которая начисляется пользователям за выполнение миссий."},
{title:"Что такое Магазин Наград?",
text:"Магазин Наград — это раздел портала, в котором вы можете обменять заработанные Протоны на реальные призы!"},
{title:"Как я могу получить Протоны?",
text:"<img src='./i/faq_how.jpg' alt='' /> Протоны вы можете заработать, проходя импакт-миссии в приложении Protone App. Импакт-миссии можно узнать по характерному уголку карточки миссии. В каждой карточке миссии указаны награды за прохождение."},
{title:"Где найти Импакт-ID?",
text:"<img src='./i/faq_impact.png' alt='' />Найти Импакт-ID можно в приложении в разделе «Настройки», далее необходимо перейти во вкладку «Профиль». Ваш импакт-ID находится под никнеймом и представляет собой сочетание букв и/или цифр."},
{title:"Как совершить обмен Протонов на товары из магазина?",
text:"Чтобы обменять Протоны, выберите приз из раздела “Награды”, нажмите кнопку “Обменять” и заполните все обязательные поля. Далее выберите  “Отправить”— после этого мы получим Вашу заявку на обмен, и как только обработаем ее, сразу напишем Вам в телеграмм для уточнения деталей и завершения обмена!"},
{title:"Сгорают ли Протоны, если не использовать их? ",
text:"Нет, Протоны не сгорают. Вы можете сразу потратить их на минимальный приз, или же оставить валюту на счету и копить на особые награды."},
{title:"Платное ли приложение Protone.app?",
text:"Приложение Protone App абсолютно бесплатно для наших пользователей!"},
{title:"Для доставки материальных призов нужны паспортные данные и ИНН. Зачем вам эта информация?",
text:"Паспортные данные нам необходимы исключительно для отчетности перед Федеральной Налоговой службой - иначе мы не сможем оформить передачу приза через бухгалтерию. Мы большая компания, и данные хранятся и обрабатываются соответствующим образом. Кроме того, спешим Вас успокоить: на данный момент на территории РФ невозможно совершить какие-либо подложные действия с Вашими документами без личного присутствия и согласия гражданина. Если вы не готовы поделиться указанными данными, Вы можете выбрать другой приз. Паспортные данные и ИНН мы запрашиваем только при выборе материального приза."},
{title:"Могу ли я оформить подписку Telegram-premium на аккаунт, на котором премиум подписка еще активна? ",
text:"Активировать подписку на аккаунте, на котором уже есть действующая подписка Telegram-premium, к сожалению, технически невозможно. В таком случае можете обменять Протоны, когда подписка закончится, или выбрать другую награду из нашего магазина."},
{title:"Платная ли доставка физических призов? ",
text:"Призы отправляем бесплатно Почтой России, если сумма обмена составляет 2500 и более Протонов. В остальных случаях доставка оплачивается при получении."},
]