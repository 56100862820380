import {useEffect} from 'react';
var ua = navigator.userAgent.toLowerCase()
export const isMobile=(/iphone/.test(ua)) || (/android/.test(ua)) // || (/ipad/.test(ua))
// eslint-disable-next-line no-extend-native
String.prototype.replaceAll = function(search, replace) 
{
return this.split(search).join(replace);
}
export const arr_menu=[
{name:'Магазин наград',a:'store'},
{name:'Частые вопросы',a:'faq'},

];
export function open_menu(item) 
{
//window.location.hash='#'+item
  if (typeof item.link!=='undefined') window.open(item.link);
  else if (typeof document.getElementById(item.a)==='undefined' || document.getElementById(item.a)===null) window.location.href='/#'+item.a
  else
   {
window.history.replaceState(null, null, document.location.pathname+'#'+item.a);
window.scrollTo({top: document.getElementById(item.a).offsetTop-80,behavior: "smooth"})
   }
}
 //for animation scroll of div
const easing=(x)=>1-Math.pow(1 - x, 4)
export function animateDivScroll(target, left, duration,scrollTop=false)
{ 
let pos,start,aFrame
const maxScroll=(scrollTop?(target.scrollHeight-target.clientHeight):(target.scrollWidth-target.clientWidth))
const init=(scrollTop?target.scrollTop:target.scrollLeft)
const amountToScroll=init-left;
  function step(curtime)
   {
  if (start===undefined) start=curtime
const elapsed=curtime-start
const progress=elapsed/duration
const eProgress=easing(progress)
pos=init-amountToScroll*Math.min(eProgress,1)
  if (scrollTop) target.scrollTo({top:pos});
  else target.scrollTo({left:pos});
  if (init!==maxScroll && target.scrollLeft===maxScroll)
   {
window.cancelAnimationFrame(aFrame)
return
   }
  if (elapsed<duration) aFrame =window.requestAnimationFrame(step);
    }
aFrame=window.requestAnimationFrame(step);
}

export function animateWindowScroll(left, duration, scrollTop=false) 
{
let pos, start, aFrame;
const maxScroll = scrollTop ? window.innerHeight - document.body.offsetTop : window.innerWidth - document.body.offsetLeft;
const init = scrollTop ? window.pageYOffset : window.pageXOffset;
const amountToScroll = init - left;
  function step(currentTime)
   {
if (start === undefined) start = currentTime;
const elapsed = currentTime - start;
const progress = elapsed / duration;
const easingProgress = easing(progress);
pos = init - amountToScroll * Math.min(easingProgress, 1);
    if (scrollTop) window.scrollTo({ top: pos });
    else  window.scrollTo({ left: pos });
    if (init !== maxScroll && window.pageXOffset === maxScroll /*|| window.pageYOffset === maxScroll*/) 
     {
      window.cancelAnimationFrame(aFrame);
      return;
     }
    if (elapsed < duration) aFrame = window.requestAnimationFrame(step);
   }
aFrame = window.requestAnimationFrame(step);
}



export function useEvent(event,handler,passive=false)
{
useEffect(()=>
  {
window.addEventListener(event, handler, passive)
    return function cleanup()
    {
window.removeEventListener(event, handler)
    }
  })
}
export function elementVisible(el)
{
el=document.getElementById(el)
const rect = el.getBoundingClientRect();
return (rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth));
}
//вернуть предыдущее, или следующее число для перехода
export function returnNext(prev,id,count)
{ 
return (prev?(id+count-1)%count:(id+1)%count)
}
export const encodeDataToURL=(data)=> 
{
return Object
.keys(data)
.map(value => `${value}=${encodeURIComponent(data[value])}`)
.join('&');
 }
 
 export function stripTags(s,allow) //only some tags is accepted
{
  if (typeof s==='undefined' || s===null) return s
  if (allow===null || typeof alllow==='undefined')  allow=['p','span','strong','em','h1','h2','h3','ol','li','ul','blockquote','a','img','video','div','br'];
  //'p','span','strong','em','h1','h2','h3','ol','li','ul','blockquote','a','img','video','div'
return s.replace( new RegExp(`\\s*<(?!\/?\\b(${allow.join('|')})\\b)[^>]+>\\s*`, 'gim' ),'');
}

export function sanitTags(s) //sanitize all tags
{
  if (typeof s==='undefined' || s===null) return s
return s.replace(/<\/?[^>]+(>|$)/gim, "");
}

 export const returnImgPath=(theme,img)=>
 {
let path='/i/black/'+img
  if (theme==='white_theme') path='/i/white/'+img
return path
 }
 export function goApp()
{
  if (/android/.test( navigator.userAgent.toLowerCase())) window.open("https://redirect.appmetrica.yandex.com/serve/316668474296386215?click_id=%7BLOGID%7D&search_term=%7Bkeyword%7D&device_type=%7Bdevice_type%7D&region_name=%7Bregion_name%7D&source_type=%7Bsource_type%7D&phrase_id=%7Bphrase_id%7D&source=%7Bsource%7D&position_type=%7Bposition_type%7D&campaign_id=%7Bcampaign_id%7D")
  else window.open("https://redirect.appmetrica.yandex.com/serve/965186825066976482?click_id=%7BLOGID%7D&search_term=%7Bkeyword%7D&device_type=%7Bdevice_type%7D&region_name=%7Bregion_name%7D&source_type=%7Bsource_type%7D&phrase_id=%7Bphrase_id%7D&source=%7Bsource%7D&position_type=%7Bposition_type%7D&campaign_id=%7Bcampaign_id%7D")
}
export const delay=(x)=> 
{
return new Promise((resolve, reject)=>
  {
setTimeout(() => {resolve('')}, x)
  });
}