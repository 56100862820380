import './faq.scss';
import {useState, useEffect, useContext, memo} from 'react';
import {ThemeContext} from './App'
import {returnImgPath, stripTags, goApp, delay} from './functions'
import {faq} from "./data"
var classNames = require('classnames');

const FaqArrow=({cls})=>
{
return(
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23 11L16 20L9 11" stroke="var(--primary-color)" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
)
}
const FaqItem=({title,text,id})=>
{
const [open, setOpen]=useState(false)
useEffect(() => { //init 
 if (id===0) setOpen(true)
   } // eslint-disable-next-line react-hooks/exhaustive-deps
,[]); /*
const scroll=(e)=>
{
const comp=(window.innerWidth>991)?110:80 //110 80
const top=e.target.getBoundingClientRect().top+window.pageYOffset-comp
  if (window.innerWidth<600) animateWindowScroll(top, 500,true)
}*/
//<img alt="" className={classNames("faq_img",{"faq_img_open":open})}  src="./i/faq_arrow.svg" />
return (
	<div className={classNames("faq_item","no_tap",{"faq_item_show":open})} onClick={(e)=>{setOpen(!open);/*scroll(e);*/}}>
<div className="faq_title no_tap"><div className="faq_title_text no_tap">{title}</div><div className="faq_title_state no_tap"><div className={classNames("faq_img",{"faq_img_open":open})}><FaqArrow  /></div></div></div>
<div className={classNames("faq_answer","no_tap",{"faq_answer_show":open})}  dangerouslySetInnerHTML={{__html: text}}></div>
			</div>
)
}

const Faq=()=>
{

const arr=faq.map((item,i) => <FaqItem title={item.title} text={item.text} id={i} key={i} />);
/*let groups=[]
  for (let i = 0; i < arrAll.length; i += 3) groups.push(arrAll.slice(i, i + 3))
const arr= groups.map((item,i) => <div className="faq_column_wrapper"  key={i}>{item}</div>);*/

return (
<div className="faq" id="faq">
<div className="faq_top_wrapper">
	<div className="faq_top_title">FAQ</div>
	<a href="https://t.me/Protone_Store_Bot" target="_blank" rel="noreferrer" className="faq_top_button">Другой вопрос</a>
</div>
		<div className="faq_wrapper">
	<div className="faq_block">
{arr}
	</div>
		</div>

</div>
)
}

export default Faq;