import './store.scss';
import {useState, useEffect, useContext, memo} from 'react';
import {ThemeContext} from './App'
import {returnImgPath, stripTags, goApp, delay} from './functions'
import {cards, cardsCategory} from "./data"
import { useNavigate } from 'react-router-dom';
var classNames = require('classnames');


const StoreCoin=({count})=>
{ //width="30" height="30"
const img=<svg  viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" className="svgCoin">
<path d="M6.69485 12.9482C7.09111 11.5156 7.86336 10.3225 8.92117 9.29044C10.573 7.67891 12.5352 6.81617 14.8309 6.74638C16.3573 6.69997 17.8073 7.03854 19.1451 7.78583C20.7512 8.68273 22.023 9.92636 22.865 11.5828C23.486 12.8045 23.7302 14.1142 23.7081 15.4819C23.683 15.8186 23.6609 16.1553 23.631 16.4912C23.6181 16.6356 23.5922 16.7793 23.5668 16.9222C23.1155 19.434 21.819 21.3926 19.7004 22.7784C17.8707 23.9749 15.851 24.377 13.6963 24.0429C11.5493 23.7099 9.78196 22.6789 8.38986 21.0099C7.23095 19.6207 6.57604 18.0125 6.42698 16.2017C6.33659 15.1028 6.39968 14.0159 6.69485 12.9482ZM8.81491 14.4717C10.152 16.5907 11.5563 18.6637 13.1063 20.6323C13.4458 21.0637 13.842 21.4427 14.3973 21.5812C15.3433 21.8169 16.1643 21.576 16.7878 20.8269C17.3494 20.152 17.8785 19.4466 18.381 18.7261C19.3193 17.3796 20.2413 16.0205 21.1401 14.647C21.3685 14.2976 21.5449 13.8885 21.6404 13.482C21.8319 12.669 20.9863 11.8846 20.1952 12.1426C19.8344 12.2603 19.5012 12.4971 19.1883 12.7251C18.3382 13.3443 17.5328 14.0288 16.6591 14.6109C15.5928 15.3219 14.4538 15.3193 13.3893 14.6065C12.5237 14.0266 11.7223 13.3495 10.8866 12.7232C10.6339 12.5335 10.3789 12.3331 10.0956 12.2009C9.306 11.8326 8.42123 12.4244 8.43119 13.2916C8.43488 13.7256 8.58947 14.1146 8.81491 14.4717Z" fill="var(--coin-color)"/>
<path d="M18.3839 4.84422C18.3648 4.92811 18.3327 5.06844 18.2578 5.21693C17.145 4.85832 15.9824 4.70092 14.781 4.73767C13.7428 4.76923 12.7598 4.93294 11.8312 5.22547C11.7799 5.12487 11.7389 5.01276 11.7109 4.88839L11.6821 4.7618C11.4508 3.74648 11.2191 2.73079 10.9885 1.71547C10.9593 1.58813 10.8715 1.2002 11.1054 0.828591C11.3393 0.457359 11.7238 0.372346 11.85 0.344504C12.8288 0.127704 13.8678 0.0152205 15.0264 0H15.0382H15.05C16.2093 0.015963 17.2361 0.125477 18.1891 0.335223C18.3703 0.374945 18.747 0.458101 18.9806 0.827849C19.2141 1.19797 19.1278 1.57774 19.0865 1.76001C18.8529 2.78833 18.6182 3.81627 18.3839 4.84422Z" fill="var(--coin-color)"/>
<path d="M25.5333 17.2627C25.5529 17.1529 25.5724 17.043 25.5883 16.9323C25.6994 16.9279 25.8226 16.9387 25.9591 16.9732C26.1432 17.02 26.331 17.0608 26.5299 17.1039C26.6247 17.1243 26.7192 17.1447 26.8136 17.1659L27.5077 17.3218C28.0474 17.4432 28.5872 17.5646 29.127 17.6845C29.689 17.8096 30.031 18.2614 29.9978 18.8353L29.9934 18.9121L29.9779 18.979L29.9775 18.9808C29.9708 19.0142 29.9565 19.0836 29.9328 19.1609C29.8808 19.3298 29.8295 19.5054 29.7797 19.6754C29.6609 20.0815 29.5377 20.5014 29.3846 20.9183C28.8798 22.2918 28.1552 23.6079 27.2313 24.8296C27.1505 24.9365 26.9048 25.2617 26.4635 25.3174C26.4211 25.3226 26.379 25.3256 26.3369 25.3256C26.0919 25.3256 25.8532 25.2342 25.6256 25.0531C25.3182 24.8084 25.0116 24.5634 24.7046 24.3188C24.1645 23.8878 23.6059 23.4415 23.0554 23.0057C22.9646 22.9337 22.8875 22.8539 22.824 22.7685C24.2357 21.2765 25.1426 19.4385 25.5333 17.2627Z" fill="var(--coin-color)"/>
<path d="M0.00699645 18.9283C0.0154826 18.736 0.104771 18.0938 0.885128 17.8993C1.63338 17.7129 2.39382 17.5217 3.12953 17.3368L4.03976 17.1082C4.16189 17.0773 4.27922 17.0629 4.39064 17.0632C4.705 19.0571 5.53554 20.8279 6.88004 22.3596C7.01766 22.5163 7.15823 22.6677 7.30139 22.8147C7.23572 22.9057 7.15418 22.9929 7.05677 23.075C6.17421 23.8178 5.36507 24.4979 4.58324 25.1539C4.47809 25.2422 4.21612 25.462 3.84236 25.4717C3.80141 25.4728 3.75935 25.4713 3.71581 25.4668C3.27564 25.4219 3.02474 25.1056 2.93029 24.9865C1.657 23.3805 0.723889 21.5767 0.157162 19.6259C0.129859 19.5316 0.102925 19.4373 0.0763594 19.3434L-1.39346e-05 19.0738L0.00699645 18.9283Z" fill="var(--coin-color)"/>
<path d="M15.0495 30C18.5742 29.9606 21.6953 28.8165 24.4024 26.5382C24.6507 26.3292 24.6492 26.2921 24.4382 26.0549C23.7419 25.2719 23.0479 24.4875 22.3532 23.7031C22.196 23.5257 22.1524 23.5193 21.9768 23.6678C20.5279 24.8925 18.8783 25.7029 17.0161 26.0434C14.647 26.4766 12.3746 26.1592 10.215 25.073C9.48705 24.7069 8.81148 24.2596 8.18904 23.7328C8.05215 23.617 7.95954 23.6329 7.84922 23.7614C7.1541 24.5703 6.45565 25.3762 5.75794 26.1829C5.56866 26.402 5.56977 26.4495 5.80111 26.6418C7.39392 27.9656 9.18229 28.9148 11.1736 29.4713C12.441 29.8251 13.7327 29.9992 15.0495 30Z" fill="var(--coin-color)"/>
<path d="M0.595425 15.4441C0.595425 15.6913 0.587677 15.9389 0.597639 16.1858C0.606863 16.4208 0.648557 16.4587 0.875838 16.4438C1.6728 16.3922 2.46902 16.335 3.26561 16.2797C3.54123 16.2604 3.81684 16.2389 4.09283 16.2211C4.2976 16.2081 4.35147 16.1516 4.34335 15.9456C4.22934 12.9899 5.15728 10.4064 7.11057 8.20318C8.09128 7.09728 9.26605 6.24418 10.6109 5.63314C10.9297 5.48836 10.9364 5.47276 10.8054 5.15425C10.4117 4.19721 10.0195 3.2398 9.62727 2.28239C9.52248 2.02624 9.48153 2.00619 9.22953 2.12053C6.08301 3.55126 3.70873 5.80168 2.13105 8.89627C1.33889 10.4503 0.86071 12.1008 0.669957 13.8337C0.611291 14.3671 0.596163 14.9058 0.561112 15.4422C0.57255 15.443 0.583987 15.4437 0.595425 15.4441Z" fill="var(--coin-color)"/>
<path d="M29.4934 15.2487C29.4702 13.2221 29.0529 11.2791 28.2113 9.43739C26.8782 6.52098 24.8106 4.27874 22.0267 2.71028C21.6316 2.48754 21.2158 2.30118 20.8066 2.10443C20.6232 2.01607 20.5564 2.04651 20.4797 2.2325C20.0642 3.2404 19.6495 4.24867 19.2355 5.25731C19.1573 5.44775 19.1806 5.49638 19.3717 5.58622C22.5577 7.08229 24.5992 9.53613 25.4662 12.9686C25.699 13.8903 25.7739 14.831 25.7503 15.7806C25.7437 16.0516 25.7547 16.0702 26.02 16.0869C26.9827 16.1478 27.946 16.2016 28.909 16.258C28.9581 16.261 29.0079 16.2573 29.0562 16.2632C29.5562 16.3238 29.4805 16.2131 29.4923 15.8601C29.499 15.6566 29.4934 15.4525 29.4934 15.2487Z" fill="var(--coin-color)"/>
</svg>
let rez=[];
  if (!count) rez=img
  else for (var i=0; i<count; i++) rez.push(<div key={"coin_"+i}>{img}</div>)
return rez
}

const StoreCard=({id,bg,bigsize,setPopup,card,count,title,bottomtitle,price,cls,top,coming})=>
	{
const bigImage=<div><img src={"/i/store/"+bg} className="store_card_img" alt="" onLoad={(e)=>e.target.style.opacity=1} /></div>
//const topImage=(top)?<div><img src={"./i/store/top/"+top} className="store_card_img_top" alt="" /></div>:''
const topImage=(top)?<div className="store_card_img_top"><StoreCoin count={1} /></div>:'' //можно подставить вместо 1 top и получить много монеток - сколько указано в json для карточки
let count_text=(count)?count+" шт.":''
let button=<div className="store_button" onClick={()=>{setPopup(id)}}>Обменять</div>
  if (coming) //будет со временем
   {
button=<div className="store_button_unact" onClick={()=>{setPopup(id)}}>Обменять</div> //Пока недоступно
count_text='Лимитировано'
//setPopup=()=>{return false}
   }
return(<div className="store_card_wrapper" onClick={()=>{ setPopup(id);}}>
<div className={"store_card "+cls}>
{topImage}
{bigImage}
<div className="price">{price}</div>
{button}
<div className="count">{count_text}</div>
<div className="title" dangerouslySetInnerHTML={{__html: title}}></div>
<div className="title_bottom" dangerouslySetInnerHTML={{__html: bottomtitle}}></div>

</div>
</div>
)
	}

	const StoreCards=memo(({cards,setPopup,current})=>
		{
const [anim,setAnim]=useState(false)
useEffect(() => {
setAnim(true)
delay(900).then(()=>setAnim(false))
}, [current]);
return(
<div className={classNames("store_cards",{"store_cards_animation":anim})}>
{cards.map((item,i)=>(!item.disabled && (current==='Всё' || item.tip.toLowerCase().indexOf(current.toLowerCase())!==-1) )?<StoreCard key={i} id={i} title={item.title} price={item.price} bottomtitle={item.bottomtitle} bg={item.bg} card={item} setPopup={setPopup} cls={item.cls} top={item.top} count={item.count} coming={item.coming} />:'')}
</div>
)
		}, (prevProps, nextProps) => prevProps.current === nextProps.current)

function CategoryButton({text,set,selected,id})
{
return(
<div className={classNames("store_button",{"store_button_act":(id===selected)})} onClick={set}>
<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 278 72" fill="none" preserveAspectRatio="none" className="store_button_svg" >
<path d="M2.69231 21.9615C-1.56172 11.7519 5.93954 0.5 17 0.5H244.333C250.591 0.5 256.234 4.26243 258.641 10.0385L275.308 50.0385C279.562 60.2481 272.06 71.5 261 71.5H33.6667C27.4093 71.5 21.7657 67.7376 19.359 61.9615L2.69231 21.9615Z" fill="var(--Surface-surface-primary)" stroke="var(--Stroke-Clickable)"/>
<defs>
<linearGradient id="paint0_linear_602_23710" x1="324.14" y1="-30.1622" x2="312.216" y2="124.502" gradientUnits="userSpaceOnUse">
<stop stopColor="#ABABAB"/>
<stop offset="1" stopColor="#ABABAB" stopOpacity="0.05"/>
</linearGradient>
</defs>
</svg>
{text}
</div>
)
}

function Store(props)
{
const {theme,category,setCategory} = useContext(ThemeContext);
const navigate = useNavigate();
const [selected,setSelected]=useState(category)
const utm=(window.location.search!=='')?'&'+window.location.search.substr(1,999):''
useEffect(() => {
setCategory(selected)
}, [selected]);

return(
<div className="store" id="store">
	<div className="store_title">
<img  src={returnImgPath(theme,"store_title.svg")} alt="" className="store_title_img" />
<div className="store_title_text">Здесь можно обменять, полученные за добрые дела в нашем приложении <span onClick={()=>goApp()} className="store_title_text_link">Protone.app</span> монетки - Протоны.
<br />Каждый сезон список наград меняется!</div>
	</div>
	<div className="store_button_wrapper">
{cardsCategory.map((item,i)=><CategoryButton text={item} key={i} set={()=>setSelected(i)} id={i} selected={selected} />)}
	</div>
<StoreCards cards={cards} setPopup={(e)=>{/*window.scrollTo(0,0);*/navigate('/card/'+e)}} current={cardsCategory[selected]} />
</div>
)
}
	

export default Store;